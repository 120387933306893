@import "../node_modules/swiper/swiper.scss";
:root {
    // --swiper-theme-color: #fff;
    --swiper-navigation-size: 1rem;
    --swiper-navigation-color: var(--dcscc-primary);
 
    --card-stack-width-mobile: 200px;
    --card-stack-height-mobile: 127px;
    --card-stack-width: 300px;
    --card-stack-height: 190px;
 
    // --dcs-cardslider-gap: 250px;
    // --dcs-cardslider-gap-invert: -250px;

    // @media screen and (min-width: 576px) {
    //     --dcs-cardslider-gap: 200px;
    //     --dcs-cardslider-gap-invert: -200px;
    // }
}
#homepageBanner {

    .swiper-buttons {
        position: absolute;
        top: auto;
        right: 1.5rem;
        bottom: 3rem;
        z-index: 10;
        --swiper-navigation-color: var(--dcscc-primary);
    }
    .swiper-button-prev,
    .swiper-button-next {
        // position: absolute;
        // top: 50%;
        // width: calc(var(--swiper-navigation-size) / 44 * 27);
        // width: var(--swiper-navigation-size);
        // height: var(--swiper-navigation-size);
        // margin-top: calc(0px - (var(--swiper-navigation-size) / 2));
        // transform: translateY(-50%);
        // z-index: 10;
        cursor: pointer;
        // display: flex;
        // align-items: center;
        // justify-content: center;
        // color: var(--swiper-navigation-color, var(--swiper-theme-color));
        // padding: var(--swiper-navigation-size);
        // background-color: var(--dcscc-light);
        border-radius: 50%;
        transition: all .3s;
        // opacity: 0;
    }
    // .swiper {
        
    //     &:hover {
    //         .swiper-button-prev,
    //         .swiper-button-next {
    //             opacity: 1;
    //         }
    //     }
    // }
    .swiper-button-prev.swiper-button-disabled,
    .swiper-button-next.swiper-button-disabled {
        opacity: 0.35;
        cursor: auto;
        pointer-events: none;
    }
    .swiper-button-prev:after,
    .swiper-button-next:after {
        font-family: swiper-icons;
        font-size: var(--swiper-navigation-size);
        text-transform: none !important;
        letter-spacing: 0;
        text-transform: none;
        font-variant: initial;
        line-height: 1;
    }
    /** /
    .swiper-button-prev,
    .swiper-rtl .swiper-button-next {
        // left: 1rem;
        // right: 0;
        padding-left: 0.95rem;
        padding-right: 1.05rem;
    }
    .swiper-button-prev:after,
    .swiper-rtl .swiper-button-next:after {
        content: '\25C4';
    }
    .swiper-button-next,
    .swiper-rtl .swiper-button-prev {
        // left: 0;
        // right: 1rem;
        padding-left: 1.05rem;
        padding-right: 0.95rem;
    }
    .swiper-button-next:after,
    .swiper-rtl .swiper-button-prev:after {
        content: '\25BA';
    }
    /**/
    .swiper-button-lock {
        display: none;
    }  

    .swiper-pagination {
        position: absolute;
        z-index: 21;
        // width: 11px;
        text-align: center;
        top: auto;
        transform: translateX(-50%);
        left: 50%;
        bottom: 1rem;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
    
        &.swiper-pagination-bullets .swiper-pagination-bullet {
            margin: 0 5px;
        }
         
        .swiper-pagination-bullet {
            width: 20%;
            height: 5px;
            display: block;
            border-radius: 10px;
            background: var(--dcscc-white);
            opacity: 0.5;
            transition: all .3s;
    
            &-active {
                opacity: 1;
                background: var(--dcscc-secondary);
                height: 5px;
                width: 20%;
            }
        }
    }
    // @media screen and (max-width: 576px) {
    //     .swiper-pagination {
    //         top: 0.5rem;
    //     }
    // }
}

.hero-bg-img {
    min-height: 400px;
    object-fit: cover;
    object-position: 50% 50%;
    
    // @media screen and (min-width: 576px) {
    //     object-position: 50% 50%;
    // }
}

.object-position-start.object-position-start {
    object-position: 0% 50%;
}

@media screen and (min-width: 576px) {
    .object-position-sm-center.object-position-sm-center {
        object-position: 50% 50%;
    }
}
